import {
  type Dispatch,
  type MouseEvent as RME,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { SET_ORDER_FIELDS } from "../../../hooks/reducers/actions/receiving/order-info";
import { PAGE_STEP } from "../../../hooks/reducers/actions/receiving/page";
import { ADD_UPGRADE_SCAN } from "../../../hooks/reducers/actions/receiving/upgrades";
// Hooks
import useScanner from "../../../hooks/use-scanner";
import GoButton from "../../components/go-button";
// Components
import UpgradeContainer from "../../components/upgrade-container";
import YesNoButtons from "../../components/yes-no-buttons";
import "./upgrades.scss";
import { UpgradesContainer } from "./upgrades/container";
import { UpgradesCard } from "./upgrades/card";

const UpgradesView = {
  SCAN_UPGRADES: 0,
  ALERT: 1,
  HAS_BACKUP: 2,
  SCAN_CARD: 3,
} as const;
type UpgradesViewState = (typeof UpgradesView)[keyof typeof UpgradesView];

const AlertOutputProducts = ["My Passport HDD", "SanDisk Ultra USB"];

type ScanUpgradesProps = {
  outputs: any;
  upgrades: any;
  dispatch: Dispatch<any>;
};
const ScanUpgrades = (props: ScanUpgradesProps) => {
  const { outputs, upgrades, dispatch } = props;
  const { list, clearScan } = useScanner();
  const prevListLength = useRef(list.length);

  useEffect(() => {
    if (list.length > prevListLength.current) {
      const entry = list[list.length - 1].code;
      dispatch({ type: ADD_UPGRADE_SCAN, scan: entry });
      clearScan();
    }
  }, [list, prevListLength, dispatch, clearScan]);

  useEffect(() => {
    prevListLength.current = list.length;
  });

  return (
    <section className="upgrades__wrapper">
      <h1 className="upgrades__header">Scan Upgrades</h1>
      <article className="upgrades__explainer">
        Click add for upgrades that fail to scan.
      </article>
      <UpgradesContainer>
        <UpgradesCard
          {...upgrades.dd}
          upgradeType="dd"
          outputs={outputs}
          dispatch={dispatch}
        />
        <UpgradesCard
          {...upgrades.td}
          upgradeType="td"
          outputs={outputs}
          dispatch={dispatch}
        />
        <UpgradesCard
          {...upgrades.dv}
          upgradeType="dv"
          outputs={outputs}
          dispatch={dispatch}
        />
      </UpgradesContainer>
    </section>
  );
};

type ConfirmHolidayPostcardProps = {
  orderInfo: any;
  dispatch: Dispatch<any>;
};
/**
 * Manually set if an order gets holiday postcard rush processing
 */
const ConfirmHolidayPostcard = ({
  orderInfo,
  dispatch,
}: ConfirmHolidayPostcardProps) => {
  const [selected, setSelected] = useState(false);
  const onClick = (isHoliday: boolean) => {
    setSelected(true);
    dispatch({
      type: SET_ORDER_FIELDS,
      payload: { isHoliday, holidayScan: "YES" },
    });
  };
  return (
    <section className="upgrades__wrapper holiday">
      <h1 className="upgrades__header">Holiday Card</h1>
      {!selected ? (
        <>
          <article className="upgrades__explainer">
            Does this order get free expedited processing?
          </article>
          <YesNoButtons
            yesClick={() => onClick(true)}
            noClick={() => onClick(false)}
          />
        </>
      ) : (
        <article className="upgrades__explainer">
          {`This order will ${
            orderInfo.isHoliday ? "" : "not"
          } get postcard rush processing.`}
        </article>
      )}
    </section>
  );
};

type AlertSpecialOutputsProps = {
  specialOutputs: any[];
  setViewState: () => void;
};
/**
 * Show an alert for outputs that are stocked in Receiving
 */
const AlertSpecialOutputs = ({
  specialOutputs,
  setViewState,
}: AlertSpecialOutputsProps) => (
  <>
    <section className="upgrades__btn prev">
      <GoButton onClick={setViewState} />
    </section>
    <section className="upgrades__wrapper">
      <h1 className="upgrades__header alert">{"ALERT"}</h1>
      <h2 className="upgrades__subheader">
        {"This order gets special delivery options:"}
      </h2>
      <ul className="upgrades__outputs-list">
        {specialOutputs.map(({ quantity, product_name }) => (
          <li
            key={`${quantity}_${product_name}`}
          >{`${quantity}x ${product_name}`}</li>
        ))}
      </ul>
    </section>
  </>
);

const findSpecialOutputs = (outputs: { product_name: string }[]) =>
  outputs.filter(({ product_name }) =>
    AlertOutputProducts.includes(product_name),
  );

const initialViewState = (
  regularOutputs: boolean,
  cardScanned: string | null,
) => {
  let viewState: UpgradesViewState = UpgradesView.SCAN_CARD;

  if (cardScanned === "YES") {
    viewState = UpgradesView.SCAN_UPGRADES;

    if (!regularOutputs) {
      viewState = UpgradesView.ALERT;
    }
  }
  return viewState;
};

function useUpgrades(props: UpgradesProps) {
  const { orderInfo, dispatch } = props;
  const specialOutputs = findSpecialOutputs(orderInfo.itemData);
  const onlyRegularOutputs = specialOutputs.length === 0;

  const [viewState, setViewState] = useState<UpgradesViewState>(
    initialViewState(onlyRegularOutputs, orderInfo.holidayScan),
  );

  /** Handle sub-steps of upgrades screen */
  const goToNextStep = useCallback(
    (event?: RME<HTMLButtonElement>) => {
      console.log({ status: "goToNextStep", event });
      if (event) {
        event.preventDefault();
        event.currentTarget.blur();
      }
      if (viewState === UpgradesView.SCAN_CARD) {
        let nextStep: UpgradesViewState = UpgradesView.SCAN_UPGRADES;
        if (!onlyRegularOutputs) {
          nextStep = UpgradesView.ALERT;
        }
        setViewState(nextStep);
        return;
      }
      if (viewState === UpgradesView.ALERT) {
        const nextStep = UpgradesView.SCAN_UPGRADES;
        setViewState(nextStep);
        return;
      }
      if (viewState === UpgradesView.SCAN_UPGRADES) {
        dispatch({ type: PAGE_STEP });
        return;
      }
    },
    [dispatch, onlyRegularOutputs, viewState],
  );

  return {
    viewState,
    setViewState,
    goToNextStep,
  };
}

type UpgradesProps = {
  orderInfo: any;
  upgrades: any;
  dispatch: Dispatch<any>;
};

export const Upgrades = ({ orderInfo, upgrades, dispatch }: UpgradesProps) => {
  const specialOutputs = findSpecialOutputs(orderInfo.itemData);
  const useUpgradesState = useUpgrades({ orderInfo, upgrades, dispatch });
  const { viewState, goToNextStep } = useUpgradesState;

  return (
    <>
      {viewState === UpgradesView.SCAN_UPGRADES && (
        <ScanUpgrades
          outputs={orderInfo.itemData}
          upgrades={upgrades}
          dispatch={dispatch}
        />
      )}
      {viewState === UpgradesView.ALERT && (
        <AlertSpecialOutputs
          specialOutputs={specialOutputs}
          setViewState={goToNextStep}
        />
      )}
      {viewState === UpgradesView.SCAN_CARD && (
        <ConfirmHolidayPostcard orderInfo={orderInfo} dispatch={dispatch} />
      )}
      <section className="upgrades__btn next">
        <GoButton onClick={goToNextStep} />
      </section>
    </>
  );
};

export default Upgrades;
