import type { OrderState } from "./order-info";

export const ADD_UPGRADE_SCAN = "ADD_UPGRADE_SCAN";
export const ADD_UPGRADE_CLICK = "ADD_UPGRADE_CLICK";
export const REMOVE_UPGRADE = "REMOVE_UPGRADE";
export const UPGRADE_QUANTITY_UPDATE = "UPGRADE_QUANTITY_UPDATE";

export type OutputUpgrade = {
  /** Added on click after sticker failed to scan */
  addedManually: boolean;
  /** Total upgrade items */
  quantity: number;
  /** Name of upgrade */
  textContent: string;
  /** Label whether upgraded item */
  upgrade: boolean;
  /** Barcode value for scanned upgrade */
  val: string;
};

export type Upgrade = {
  /** Digital download */
  dd: OutputUpgrade;
  /** Thumb drive */
  td: OutputUpgrade;
  /** DVD disc set */
  dv: OutputUpgrade;
};
type UpgradeKey = keyof Upgrade;
export const genericUpgrades = {
  download: "dd",
  dvdcdset: "dv",
  thumbdrive: "td",
} as const;
type GenericUpgrades = typeof genericUpgrades;
type UpgradeScan = {
  type: "ADD_UPGRADE_SCAN";
  scan: keyof GenericUpgrades;
};
type AllUpgrades = UpgradeKey | keyof GenericUpgrades;

export const isUpgradeKey = (key: string): key is UpgradeKey =>
  key in upgradeInitialState.upgrades;
export const isUpgradeType = (type: string): type is AllUpgrades =>
  type in genericUpgrades || isUpgradeKey(type);

/**
 * Prevent adding another digital download as an upgrade
 * if a customer already has a download output
 */
export const blockMultipleDigitalDownloadUpgrades = (
  hasDownload: boolean,
  upgrades: Upgrade,
  newUpgradeType: UpgradeKey,
) => {
  /** Thumb drives, disc sets can have multiple quantities always */
  if (newUpgradeType !== "dd") {
    return false;
  }
  if (hasDownload || upgrades.dd.quantity >= 1) {
    return true;
  }
};
export const addUpgradeScan = (
  { orderInfo, upgrades }: ReceivingState,
  { scan }: UpgradeScan,
) => {
  // Match order specific upgrade stickers ("123456-dd")
  // or generic stickers ("download")
  const type = (scan.split("-")[1] || genericUpgrades[scan]) as keyof Upgrade;
  const thisUpgrade = upgrades[type];

  if (
    thisUpgrade === undefined ||
    !thisUpgrade ||
    (thisUpgrade && thisUpgrade.upgrade)
  ) {
    return {};
  }

  if (
    blockMultipleDigitalDownloadUpgrades(
      orderInfo.hasDownloadOutput,
      upgrades,
      type,
    )
  ) {
    return {
      upgrades,
    };
  }

  return {
    upgrades: {
      ...upgrades,
      [type]: {
        ...thisUpgrade,
        quantity: thisUpgrade.quantity + 1,
        val: scan.toUpperCase(),
        upgrade: true,
      },
    },
  };
};

type UpgradeQuantityUpdate = {
  type: "UPGRADE_QUANTITY_UPDATE";
  upgradeType: keyof Upgrade;
  action: "add" | "remove";
};

export const updateUpgradeQuantity = (
  state: ReceivingState,
  action: UpgradeQuantityUpdate,
) => {
  const { upgrades, orderInfo } = state;
  const { upgradeType, action: updateAction } = action;
  const thisUpgrade = upgrades[upgradeType];

  if (
    thisUpgrade === undefined ||
    !thisUpgrade ||
    (thisUpgrade && !thisUpgrade.upgrade)
  ) {
    return {};
  }

  if (
    blockMultipleDigitalDownloadUpgrades(
      orderInfo.hasDownloadOutput,
      upgrades,
      upgradeType,
    )
  ) {
    return {
      upgrades,
    };
  }

  return {
    upgrades: {
      ...upgrades,
      [upgradeType]: {
        ...thisUpgrade,
        quantity:
          updateAction === "add"
            ? thisUpgrade.quantity + 1
            : thisUpgrade.quantity - 1,
      },
    },
  };
};

export const addUpgradeQuantity = (upgradeType: keyof Upgrade) => ({
  type: UPGRADE_QUANTITY_UPDATE,
  upgradeType,
  action: "add",
});

export const removeUpgradeQuantity = (upgradeType: keyof Upgrade) => ({
  type: UPGRADE_QUANTITY_UPDATE,
  upgradeType,
  action: "remove",
});

type UpgradeClick = {
  type: "ADD_UPGRADE_CLICK";
  upgradeType: keyof Upgrade;
};

export const addUpgradeClickAction = (upgradeType: string) => {
  if (!isUpgradeType(upgradeType)) {
    throw new Error(`Invalid upgrade type: ${upgradeType}`);
  }
  return {
    type: ADD_UPGRADE_CLICK,
    upgradeType,
  };
};
export const addUpgradeClick = (
  { orderInfo, upgrades }: ReceivingState,
  { upgradeType }: UpgradeClick,
) => {
  const thisUpgrade = upgrades[upgradeType];

  if (
    thisUpgrade === undefined ||
    !thisUpgrade ||
    (thisUpgrade && thisUpgrade.upgrade)
  ) {
    return {};
  }

  if (
    blockMultipleDigitalDownloadUpgrades(
      orderInfo.hasDownloadOutput,
      upgrades,
      upgradeType,
    )
  ) {
    return {
      upgrades,
    };
  }

  return {
    upgrades: {
      ...upgrades,
      [upgradeType]: {
        ...thisUpgrade,
        quantity: thisUpgrade.quantity + 1,
        val: upgradeType.toUpperCase(),
        upgrade: true,
        addedManually: true,
      },
    },
  };
};

type RemoveUpgradeClick = {
  type: "REMOVE_UPGRADE";
  upgradeType: keyof Upgrade | keyof GenericUpgrades;
};
export const removeUpgradeClick = (upgradeType: string) => {
  if (!isUpgradeType(upgradeType)) {
    throw new Error(`Invalid upgrade type: ${upgradeType}`);
  }
  return {
    type: REMOVE_UPGRADE,
    upgradeType,
  };
};
export const removeUpgrade = (
  { upgrades }: UpgradeState,
  { upgradeType }: RemoveUpgradeClick,
) => {
  const genType = genericUpgrades[upgradeType as keyof GenericUpgrades];
  const thisUpgrade =
    upgrades[upgradeType as keyof Upgrade] || upgrades[genType];

  if (
    thisUpgrade === undefined ||
    !thisUpgrade ||
    (thisUpgrade && !thisUpgrade.upgrade)
  ) {
    return {};
  }

  return {
    upgrades: {
      ...upgrades,
      [upgradeType]: {
        ...thisUpgrade,
        quantity: thisUpgrade.quantity - 1,
        val: upgradeType.toUpperCase(),
        upgrade: false,
        addedManually: false,
      },
    },
  };
};

type UpgradeState = {
  upgrades: Upgrade;
};
type ReceivingState = UpgradeState & OrderState;

export type UpgradeAction =
  | UpgradeScan
  | UpgradeClick
  | RemoveUpgradeClick
  | UpgradeQuantityUpdate;

export const upgradeInitialState: UpgradeState = {
  upgrades: {
    dd: {
      textContent: "Digital Download",
      quantity: 0,
      upgrade: false,
      addedManually: false,
      val: "",
    },
    td: {
      textContent: "Thumb Drive",
      quantity: 0,
      upgrade: false,
      addedManually: false,
      val: "",
    },
    dv: {
      textContent: "DVD Set",
      quantity: 0,
      upgrade: false,
      addedManually: false,
      val: "",
    },
  },
};

export const upgradeActions = {
  ADD_UPGRADE_SCAN: addUpgradeScan,
  ADD_UPGRADE_CLICK: addUpgradeClick,
  REMOVE_UPGRADE: removeUpgrade,
  UPGRADE_QUANTITY_UPDATE: updateUpgradeQuantity,
};
