import {
  addUpgradeClickAction,
  addUpgradeQuantity,
  isUpgradeType,
  removeUpgradeClick,
  removeUpgradeQuantity,
} from "@/hooks/reducers/actions/receiving/upgrades";
import BaseButton from "@/ui/base/button";
import TextInput from "@/ui/base/text-input";
import UpgradeIndicator from "@ui/components/upgrade-indicator";
import { Dispatch } from "react";

import "./card.scss";

interface UpgradesCardProps {
  /** Title marker */
  textContent: string;
  /** Total number of Upgrade/Outputs of type */
  quantity: number;
  /** Notes if upgrades were scanned */
  upgrade: boolean;
  /** Assigned barcode value for input display */
  val: string;
  /** Short code for output (upgrades keys: 'dd', 'td, 'dv) */
  upgradeType: "dd" | "td" | "dv";
  /** dispatch function for reducer */
  dispatch: Dispatch<any>;
  /** Array of upgrade outputs */
  outputs: { output_type: string; quantity: number }[];
}

export const UpgradesCard = (props: UpgradesCardProps) => {
  const {
    outputs,
    textContent,
    quantity,
    upgrade,
    val,
    upgradeType,
    dispatch,
  } = props;

  console.log(props);

  const outputIndex = outputs.findIndex(
    ({ output_type }) => output_type === textContent,
  );

  let displayQuantity = Number(quantity);
  if (outputIndex > -1) {
    displayQuantity += Number(outputs[outputIndex].quantity);
  }
  if (!isUpgradeType(upgradeType)) {
    throw new Error(`Invalid upgrade type: ${upgradeType}`);
  }

  const shouldDisplayAddBtn = textContent !== "Digital Download";

  let upgradeBtn = <div />;

  if (upgrade) {
    upgradeBtn = (
      <div className="upgrades-card__edit-wrapper">
        <BaseButton
          className="subtract-btn"
          disabled={displayQuantity === 1}
          onClick={() => dispatch(removeUpgradeQuantity(upgradeType))}
        >
          -
        </BaseButton>
        <div className="upgrades-card__text-input-wrapper">
          <BaseButton
            className="upgrades-card__text-input-btn"
            onClick={(event) => {
              event.currentTarget.blur();
              dispatch(removeUpgradeClick(upgradeType));
            }}
          >
            X
          </BaseButton>
          <TextInput
            val={`${val} - ${displayQuantity}`}
            className="upgrades-card__text-input"
          />
        </div>
        <BaseButton
          className="add-btn"
          onClick={() => dispatch(addUpgradeQuantity(upgradeType))}
        >
          +
        </BaseButton>
      </div>
    );
  } else if (!upgrade && shouldDisplayAddBtn) {
    upgradeBtn = (
      <BaseButton
        className="upgrades-card__add-btn"
        onClick={() => {
          dispatch(addUpgradeClickAction(upgradeType));
        }}
      >
        Add
      </BaseButton>
    );
  }

  return (
    <>
      <UpgradeIndicator
        textContent={textContent}
        quantity={displayQuantity}
        upgrade={upgrade}
      />
      {upgradeBtn}
    </>
  );
};
